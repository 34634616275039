export default {
  mounted() {
    this.disableAutoComplete();
  },
  methods: {
    // https://github.com/aacassandra/vue-disable-autocomplete/blob/master/dist/vue-disable-autocomplete.js
    disableAutoComplete() {
      const elements = document.querySelectorAll('[autocomplete="off"]');

      if (!elements) {
        return;
      }

      elements.forEach((element) => {
        element.setAttribute('readonly', 'readonly');
        element.style.backgroundColor = 'inherit'; // eslint-disable-line no-param-reassign

        setTimeout(() => {
          element.removeAttribute('readonly');
        }, 500);
      });
    },
  },
};
