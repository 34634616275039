<template>
  <v-container>
    <v-dialog
      v-model="dialog"
      max-width="50vw"
    >
      <v-card>
        <v-card-title class="text-h5 grey lighten-2">
          Advance Mapping
        </v-card-title>

        <v-card-text>
          <v-col>
            <v-select
              :items="advanceMappingFilter"
              label="Select Filter"
              item-text="caption"
              item-value="value"
              v-model="regexMapper.filterType"
              dense
            ></v-select>
          </v-col>
          <v-col>
            <v-text-field
              label="Enter Text Length"
              v-model="regexMapper.textLength"
            ></v-text-field>
          </v-col>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            @click="closeDialog"
          >
            OK
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-row>
      <v-col>
        <h3 class="text-center">{{ pageTitle }}</h3>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="6">
        <v-text-field
          label="Profile Name"
          v-model="formItems.type"
        ></v-text-field>
      </v-col>
      <v-col cols="6">
        <v-select
          v-model="formItems.action"
          :items="dataDestionationsList"
          label="Function of the Data Importing"
          item-value="action"
          item-text="description"
          @change="onSelectProfile($event)"
          dense
        ></v-select>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="6">
        <v-file-input
          accept=".csv"
          label="Import (.csv)"
          show-size
          clearable
          @change="onFileSelected"
        >
        </v-file-input>
      </v-col>
      <v-col cols="6">
        <v-checkbox
          v-model="formItems.noheader"
          :label="'Is file headerless?'"
        ></v-checkbox>
      </v-col>
    </v-row>
    <v-row v-if="isDataUploaded">
      <v-col>
        <h3 class="text-center">Your Uploaded File's Data</h3>
      </v-col>
    </v-row>
    <v-row v-if="isDataUploaded">
      <v-col>
        <template>
          <v-data-table
            :headers="headers"
            :items="sampleDataItems"
            :items-per-page="5"
            :hide-default-footer="true"
            class="elevation-1"
          ></v-data-table>
        </template>
      </v-col>
    </v-row>
    <v-row v-if="isDataUploaded || isEditMode">
      <v-col>
        <h3 class="text-center">Actions for mapping of Fields to Destination of imported data</h3>
      </v-col>
    </v-row>
    <v-row v-if="isDataUploaded || isEditMode">
       <v-col cols="2">
        <h3></h3>
      </v-col>
      <v-col cols="2">
        <h3>Is Mapped</h3>
      </v-col>
      <v-col cols="2">
        <h3>Destination</h3>
      </v-col>
      <v-col cols="2">
        <h3>Formatter</h3>
      </v-col>
      <v-col cols="2">
        <h3>Keep Only</h3>
      </v-col>
    </v-row>
    <v-row v-for="(mapping, index) in formItems.mappingConfig" :key="mapping.id">
      <v-col cols="2.4">
        <h3>{{ mapping.srcColumnName }}</h3>
      </v-col>
      <v-col cols="2.4">
        <v-select
            v-model="mapping.action"
            :items="mappingActionsList"
          ></v-select>
      </v-col>
      <v-col cols="2.4">
        <v-select
            v-model="mapping.destField"
            :items="destionationFields"
          ></v-select>
      </v-col>
      <v-col cols="2.4">
        <v-select
            v-model="mapping.formatter"
            :items="fieldDataTypesList"
          ></v-select>
      </v-col>
      <v-col cols="2.4">
        <v-text-field
          v-model="mapping.keepValue"
          label="Keep only"
          autocomplete="off"
          :id="`configImportProfileKeepOnly_${index}`"
          :name="`configImportProfileKeepOnly_${index}`"
        ></v-text-field>
      </v-col>
      <v-col cols="2.4">
        <v-btn
          @click="openDialog(mapping)"
        >
          Advance Mapping
        </v-btn>
      </v-col>
    </v-row>
    <v-row v-if="isDataUploaded || isEditMode">
      <v-spacer></v-spacer>
      <v-col>
        <v-btn
          depressed
          color="primary"
          @click="saveProfile"
        >
          Save Profile
        </v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import csv from 'csvtojson';
import LineNavigator from 'line-navigator';
import GlobalMixin from '../../util/shared/GlobalMixin';

const defaultRegexMapper = () => ({
  filterType: '',
  textLength: null,
});

const initdata = () => ({
  isDataUploaded: false,
  isEditMode: false,
  hasHeaders: true,
  sampleData: [], // CSV JSON
  mappingData: [],
  mappingSrcColumns: [],
  mappingActions: [],
  mappingDestField: [],
  mappingActionsList: ['ignore', 'map'],
  fieldDataTypesList: ['', 'Number', 'Date', 'Number(Positive)'],
  fieldDataTypes: [],
  headers: [],
  items: [],
  pageTitle: '',
  dialog: false,
  advanceMappingFilter: [{
    caption: '',
    value: '',
  }, {
    caption: 'Select Text From Right',
    value: 'fromRight',
  }, {
    caption: 'Select Text From Left',
    value: 'fromLeft',
  }],
  regexMapper: defaultRegexMapper(),
});

export default {
  name: 'ConfigImportProfile',
  mixins: [GlobalMixin],
  data: () => initdata(),
  created() {
    const profileID = this.$route.params.id;
    if (profileID !== undefined) {
      this.pageTitle = 'EDIT PROFILE';
      this.isEditMode = true;
      this.$store.dispatch('Bulk/fetchProfileByID', profileID);
    } else {
      this.pageTitle = 'NEW PROFILE';
      this.$store.commit('Bulk/setProfile', {});
    }
    this.$store.dispatch('Bulk/loadDataDestinations');
  },
  computed: {
    dataDestionationsList() {
      return this.$store.state.Bulk.dataDestionationsList
        .filter((d) => d.action === 'importBankVoucher');
    },
    destionationFields() {
      return ['', ...this.$store.state.Bulk.destionationFields];
    },
    formItems() {
      // a way of deep copy
      return JSON.parse(JSON.stringify(this.$store.state.Bulk.profileData));
    },
  },
  methods: {
    saveProfile() {
      if (window.confirm('Are you sure want to save this profile?')) {
        this.$store.dispatch('Bulk/upsertProfile', this.formItems).then(() => {
          this.$router.push({
            name: 'View Import Files Profile',
            params: { id: this.formItems.id },
          });
        });
      }
    },
    onSelectProfile(action) {
      this.$store.dispatch('Bulk/fetchDestionationByAction', action);
    },
    openDialog(mapping) {
      this.dialog = true;
      if (mapping && mapping.regex) {
        this.regexMapper = mapping.regex;
      } else {
        // eslint-disable-next-line no-param-reassign
        mapping.regex = defaultRegexMapper();
        this.regexMapper = mapping.regex;
      }
    },
    closeDialog() {
      this.dialog = false;
      this.regexMapper = defaultRegexMapper();
    },
    onFileSelected(file) {
      const self = this;
      // Need to improve !
      self.sampleData = [];
      self.mappingData = [];
      self.mappingSrcColumns = [];
      self.mappingActions = [];
      self.mappingDestField = [];
      self.fieldDataTypes = [];
      self.headers = [];
      self.items = [];
      // load and store sample as part of profile definition
      const navigator = new LineNavigator(file, {
        encoding: 'utf8',
        chunkSize: 1024,
      });
      const linesReadHandler = async (err, _, lines) => {
        if (err) {
          self.flashError(`Error loading preview data => ${err}`);
        }
        // including header load only 5 lines
        const previewLines = lines.slice(0, 5);
        csv({
          output: 'json',
          noheader: this.formItems.noheader,
        })
          .fromString(previewLines.join('\n'))
          .then((jsonItems) => {
            self.sampleData = jsonItems;
            // todo - support w/ and w/o headers
            let headerRow;
            let firstRow;
            if (self.hasHeaders) {
              // eslint-disable-next-line no-unused-vars
              [headerRow, firstRow] = jsonItems;
            } else {
              [firstRow] = jsonItems;
            }
            const formatedHeaders = [];
            const jsonMappings = [];
            const keys = Object.keys(firstRow);
            for (let i = 0; i < keys.length; i += 1) {
              let propName = '';
              if (self.hasHeaders) {
                propName = keys[i];
              }
              propName = keys[i];
              self.mappingSrcColumns.push(propName);
              jsonMappings.push({
                srcColumnName: propName,
                action: undefined,
                destField: undefined,
                formatter: undefined,
              });
              formatedHeaders.push({ text: keys[i], value: keys[i] });
            }
            self.sampleDataItems = jsonItems;
            self.headers = formatedHeaders;
            self.formItems.mappingConfig = jsonMappings;
            self.isDataUploaded = true;
          });
      };
      navigator.readSomeLines(0, linesReadHandler);
    },
  },
};
</script>
